import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "The Walt Disney Company": {
      jobTitle: "Data Scientist @",
      duration: "SEP 2020 - CURRENT",
      desc: [
        "Spearheaded the development, automation, and deployment of a dynamic model to calculate weekly trending and popularity rankings for Marvel comics within the re-launched Marvel Unlimited App, optimizing user engagement and retention.",
        "Contributed to the development, automation, and A/B testing of a recommendation algorithm for ESPN users with machine learning, resulting in a notable increase in user engagement.",
        "Led the launch, optimization, and automation of multiple crucial A/B tests, quantifying the direct impact of personalized algorithms and models on ESPN's homefeed, thereby enhancing user experience and maximizing engagement metrics.",
        "Conducted comprehensive ad-hoc analyses of modeling and experimentation results to address specific business inquiries and challenges, providing actionable insights to drive strategic decision-making.",
        "Established and maintained a suite of Tableau dashboards across teams to effectively report and track key performance indicators, providing valuable insights to monitor and optimize business performance.",
        "Presented work on the Marvel Unlimited App at the Disney Data \& Analytics Conference (DDAC) 2022 Innovation Showcase.",
        "Presented a Level Up Lab on A/B testing at the Grace Hopper Conference (GHC) 2023, empowering women in STEM to explore the application of technology in decision science and analytics."
      ]
    },
    Verizon: {
      jobTitle: "Software Engineering Intern @",
      duration: "JUNE 2018,2019 - AUG 2018,2019",
      desc: [
        "Leveraged sentiment analysis, unsupervised machine learning, and topic modeling to analyze chat data and online surveys from the Verizon Wireless website, uncovering key trends and customer preferences.",
        "Developed a self-service Tableau dashboard to visualize and communicate key insights, results, and recommendations, empowering stakeholders to make informed decisions and enhance the overall customer experience.",
        "Employed a Random Forest supervised machine learning model to predict optimal discount percentages for securing enterprise deals, effectively balancing profitability and customer satisfaction."
      ]
    },
    "Georgia Tech": {
      jobTitle: "B.S. Electrical Engineering @",
      duration: "AUG 2015 - MAY 2020",
      desc: [
        "Collaborated on the creation of a drone simulation to detect explosive chemicals for the GT Capstone Engineering Expo.",
        "Scraped data from New York Times to analyze the relationship between household income and population density on COVID-19 case and fatality rate in the state of Georgia.",
        "Served as Vice President and Industry Relations Chair of the Women in ECE (WECE) academic organization."
      ]
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function(descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
